import { Image } from "semantic-ui-react";

import activeLogo from "./options/MPIconActive.png";
import registeredLogo from "./options/MPIconReg.png";
import invitedLogo from "./options/MPIconInvited.png";

type LogoStates = {
  active: string;
  registered: string;
  invited: string;
  [key: string]: string;
};

const logoStates: LogoStates = {
  active: activeLogo,
  registered: registeredLogo,
  invited: invitedLogo,
};

type MarketplaceIconProps = {
  state: string | null;
};

const MarketplaceIcon = (props: MarketplaceIconProps) => {
  const { state = null } = props;

  if (state === null) {
    return null;
  }

  return (
    <div>
      <Image src={logoStates[state]} data-testid="marketplace-icon" />
    </div>
  );
};

export default MarketplaceIcon;
