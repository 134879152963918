import { useEffect } from "react";
import useSWRInfinite from "swr/infinite";
import axios, { AxiosError, AxiosResponse } from "axios";
import { QueryGridResponse } from "models/QueryGridResponse";
import { CarrierTimelineDto } from "models/dto/CarrierTimelineDto";

type ActivityTimelineProps = {
  carrierId: string | number;
  filterData?: FilterProps;
}

type FilterProps = {
  carrierDetailsChanges: boolean;
  capacityChanges: boolean;
  ownerShipChanges: boolean;
  attributeChanges: boolean;
}

const useGetCarrierActivityTimelineInfinite = ({ carrierId, filterData }: ActivityTimelineProps) => {
  const getKey = (
    page: number,
    previousPageData: QueryGridResponse<CarrierTimelineDto>
  ) => {
    if (previousPageData && !previousPageData.entities.length) {
      return null;
    }

    const params = new URLSearchParams({
      page: (page + 1).toString(),
    });

    return params.toString();
  };

  const swr = useSWRInfinite<QueryGridResponse<CarrierTimelineDto>, AxiosError>(
    getKey,
    async (url: string) => {
      const urlSearchParams = new URLSearchParams(url);
      const page = Number(urlSearchParams.get("page"));
      const body = {
        pageSize: 25,
        sortField: "createdDate",
        dir: "desc",
        ...filterData,
      };

      const response = await axios.post<
        QueryGridResponse<CarrierTimelineDto>,
        AxiosResponse<QueryGridResponse<CarrierTimelineDto>>,
        typeof body & { page: number }
      >(`/api/Timeline/${carrierId}/carrierActivities/list`, { ...body, page });

      return response.data;
    },
    {
      keepPreviousData: false,
      revalidateOnFocus: true,
      shouldRetryOnError: false,
      revalidateFirstPage: false,
      revalidateOnMount: true,
      persistSize: false,
    }
  );

  useEffect(() => {
    swr.setSize(1);
  }, []);

  const reloadActivities = async () => {
    const container = document.getElementById("activities-timeline-v2");
    if (container) {
      container.scrollTo({ top: 0, behavior: "smooth" });
    }

    await swr.mutate();
    await swr.setSize(1);
  };

  return {
    activities: swr.data?.flatMap((data) => data.entities) ?? [],
    totalActivities: swr.data?.[0]?.filteredCount ?? 0,
    isLoading: swr.isLoading || (!swr.error && swr.size > 0 && !swr.data?.[swr.size - 1]),
    isReloading: swr.isValidating,
    getNextPage: () => swr.setSize(swr.size + 1),
    reloadActivities,
  };
};

export default useGetCarrierActivityTimelineInfinite;
