import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "api/customerAPI";
import { GetProfilesDocumentPageQuery } from "models/dto/GetProfilesDocumentPageQuery";
import { ProfilesDocumentDto } from "models/dto/ProfilesDocumentDto";
import { QueryGridResponse } from "models/QueryGridResponse";
import { toast } from "react-toastify";

type CustomerIdGridState = {
  customerId: number;
  gridState: GetProfilesDocumentPageQuery;
};

type CustomerDocumentsSliceState = {
  list: QueryGridResponse<ProfilesDocumentDto>;
  exportFileUrl: string;
  loading: boolean;
  error: string | null;
  customerEditingItem: string;
  gridState: GetProfilesDocumentPageQuery & { total?: number };
};

const fetchCustomerDocuments = createAsyncThunk<
  QueryGridResponse<ProfilesDocumentDto>,
  CustomerIdGridState
>("customerDocuments/fetch", async ({ customerId, gridState }) => {
  const response = await API.fetchCustomerDocuments(customerId, gridState);
  return response.data;
});

const filterCustomerDocuments = createAsyncThunk<
  QueryGridResponse<ProfilesDocumentDto>,
  CustomerIdGridState
>("customerDocuments/filter", async ({ customerId, gridState }) => {
  const response = await API.filterCustomerDocuments(customerId, gridState);
  return response.data;
});

const saveDocumentNotes = createAsyncThunk<
  void,
  CustomerIdGridState & {
    notes: {
      id: number;
      notes: string;
    };
  }
>(
  "customerDocuments/saveNote",
  async ({ customerId, notes, gridState }, thunkAPI) => {
    await API.saveDocumentNotes(customerId, notes);
    thunkAPI.dispatch(filterCustomerDocuments({ customerId, gridState }));
  }
);

const addCustomerDocument = createAsyncThunk<
  void,
  CustomerIdGridState & { formData: FormData }
>(
  "addCustomerDocument/addDocument",
  async ({ customerId, formData, gridState }, thunkAPI) => {
    const response = await API.addCustomerDocument(customerId, formData);
    if (response) {
      toast.success("New document added successfully!");
      thunkAPI.dispatch(filterCustomerDocuments({ customerId, gridState }));
    }
  }
);

const updateCustomerDocument = createAsyncThunk<
  void,
  CustomerIdGridState & { formData: FormData }
>(
  "updateCustomerDocument/saveDocument",
  async ({ customerId, formData, gridState }, thunkAPI) => {
    const response = await API.updateCustomerDocument(customerId, formData);
    if (response) {
      toast.success("Document details updated successfully!");
      thunkAPI.dispatch(filterCustomerDocuments({ customerId, gridState }));
    }
  }
);

const deleteCustomerDocument = createAsyncThunk<
  void,
  CustomerIdGridState & { documentId: number }
>(
  "deleteCustomerDocument/removeDocument",
  async ({ customerId, documentId, gridState }, thunkAPI) => {
    await API.deleteCustomerDocument(customerId, documentId);
    thunkAPI.dispatch(filterCustomerDocuments({ customerId, gridState }));
  }
);

const initialState: CustomerDocumentsSliceState = {
  list: {
    filteredCount: 0,
    entities: [],
  },
  exportFileUrl: "",
  loading: false,
  error: null,
  customerEditingItem: "",
  gridState: {
    page: 1,
    pageSize: 10,
    total: 0,
    sortField: "name",
    dir: "ASC",
    name: "",
  },
};

export const customerDocumentsSlice = createSlice({
  name: "customerDocuments",
  initialState,
  reducers: {
    resetAttributes: (state) => {
      state.list = { ...initialState.list };
    },
    addNewDocumentInfo: (state) => {
      const newDocumentInfoItem = {
        id: 0,
      } as ProfilesDocumentDto;
      state.list.entities.unshift(newDocumentInfoItem);
    },
    removeNewDocumentInfo: (state) => {
      state.list.entities.shift();
    },
    setCustomerEditingDocument: (state, action) => {
      state.customerEditingItem = action.payload;
    },
    updateCustomerGridState: (state, action) => {
      state.gridState = { ...state.gridState, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchCustomerDocuments.pending,
      (state: CustomerDocumentsSliceState) => {
        state.loading = true;
      }
    );
    builder.addCase(fetchCustomerDocuments.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action.payload;
      if (!action.payload || action.payload.entities.length === 0) {
        state.error = "No Document was found";
      }
    });
    builder.addCase(fetchCustomerDocuments.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message ?? null;
    });

    builder.addCase(filterCustomerDocuments.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(filterCustomerDocuments.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action.payload;
    });
    builder.addCase(filterCustomerDocuments.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(saveDocumentNotes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(saveDocumentNotes.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(saveDocumentNotes.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateCustomerDocument.fulfilled, (state) => {
      state.customerEditingItem = "";
    });
  },
});

export const actions = {
  ...customerDocumentsSlice.actions,
  fetchCustomerDocuments,
  filterCustomerDocuments,
  saveDocumentNotes,
  addCustomerDocument,
  updateCustomerDocument,
  deleteCustomerDocument,
};

export const reducer = customerDocumentsSlice.reducer;
