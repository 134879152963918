// components
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";
// styles
import styles from "./PageHeader.module.scss";
import SearchBar from "components/Navigation/SearchBar/SearchBar";
import { carrierGroupButtons } from "components/Navigation/SearchBar/buttonsForGroup";
import { LegacyRef, useState, ReactNode } from "react";

type PageHeaderProps = {
  onSearch?: (value: string, searchByValue: string) => void;
  filter?: ReactNode;
  sorting?: ReactNode;
  headers?: ReactNode;
  searchTitle?: string;
  autoTypeOfInputSearch?: boolean;
  inputRef?: LegacyRef<HTMLInputElement> | null;
  checkedButtons?: string;
};

type CarrierGroupButtonProps = {
  id: number;
  labelName: string;
  checked: boolean;
  fieldName: string;
};

const PageHeader = (props: PageHeaderProps) => {
  const [checkedIDs] = useState<number[]>(() => {
    if (!props.checkedButtons) return [1];
    const fieldNamesArray = props.checkedButtons.split(",");

    return carrierGroupButtons
      .filter((button) => fieldNamesArray.includes(button.fieldName))
      .map((button) => button.id);
  });

  const getCheckedButtonNamesString = (
    checkedIDs: number[],
    carrierGroupButtons: CarrierGroupButtonProps[]
  ) => {
    const checkedButtonNames = checkedIDs
      .map((id) => {
        const button = carrierGroupButtons.find(
          (button: CarrierGroupButtonProps) => button.id === id
        );
        return button ? button.fieldName : null;
      })
      .filter(Boolean);

    return checkedButtonNames.join(",");
  };

  const searchByField = getCheckedButtonNamesString(
    checkedIDs,
    carrierGroupButtons
  );

  return (
    <div className={styles.header}>
      <div className={styles.pageHeader}>
        <Breadcrumbs />
        {props.onSearch ? (
          <div className={styles.searchBarArea}>
            <SearchBar
              onSearch={(value) =>
                props.onSearch ? props.onSearch(value, searchByField) : null
              }
              inputPlaceholder={props.searchTitle ?? "Search..."}
              autoTypeOfInputSearch={props.autoTypeOfInputSearch}
              inputRef={props.inputRef}
            />
          </div>
        ) : null}
        {props.filter ? (
          <div className={styles.filterSection}>
            {props.sorting}
            {props.filter}
          </div>
        ) : null}
      </div>
      <div>{props.headers}</div>
    </div>
  );
};

export default PageHeader;
