import { useMemo } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetValue,
} from "react-hook-form";
import {
  Dropdown,
  DropdownProps,
  Form,
  Input,
  Segment,
  TextArea,
} from "semantic-ui-react";

import { CoveragePlanDto } from "models/dto/CoveragePlanDto";

import styles from "./CarrierPlanCapacityForm.module.scss";
import { integerReplace } from "utils/integerReplace";
import { convertToFormDateString } from "pages/CoveragePlans/CoveragePlanForm/utils/coveragePlanApiToFormMappers";
import { Option } from "models/Option";
import { CarrierPlanCapacityTypeInfoDto } from "models/dto/CarrierCoveragePlanCapacityDto";
import { SemanticElement } from "models/SemanticElement";

export type CarrierPlanCapacityFormObject = {
  id: string;
  type: CarrierPlanCapacityTypeInfoDto;
  planId: number | null;
  laneId: number | null;
  rate: string;
  equipment: { id: number; name: string } | null;
  coverageAssigned: string;
  note: string;
};

type CarrierPlanCapacityFormProps = {
  closeForm: () => void;
  coveragePlans: CoveragePlanDto[];
  defaultValues: CarrierPlanCapacityFormObject;
  control: Control<CarrierPlanCapacityFormObject>;
  errors: FieldErrors<CarrierPlanCapacityFormObject>;
  setValue: UseFormSetValue<CarrierPlanCapacityFormObject>;
  planId: CarrierPlanCapacityFormObject["planId"];
  laneId: CarrierPlanCapacityFormObject["laneId"];
};

const CarrierPlanCapacityForm = (props: CarrierPlanCapacityFormProps) => {
  const { planId, laneId, coveragePlans } = props;
  const selectedCoveragePlan = useMemo(
    () => coveragePlans.find((plan) => (plan.id ? plan.id === planId : false)),
    [coveragePlans, planId]
  );

  const selectedCoveragePlanLane = useMemo(
    () =>
      selectedCoveragePlan?.lanes.find((lane) =>
        lane.id ? lane.id === laneId : false
      ),
    [selectedCoveragePlan, laneId]
  );

  const coveragePlanOptions = useMemo(
    () =>
      coveragePlans.reduce<Option<number>[]>((acc, plan) => {
        if (plan.id) {
          acc.push({
            key: plan.id,
            value: plan.id,
            text: plan.customer
              ? `${plan.name} - (${plan.customer.name})`
              : plan.name,
          });
        }

        return acc;
      }, []) ?? [],
    [coveragePlans]
  );

  const coveragePlanLaneOptions = useMemo(
    () =>
      selectedCoveragePlan?.lanes.reduce<Option<number>[]>((acc, lane) => {
        if (lane.id) {
          acc.push({
            key: lane.id,
            value: lane.id,
            text: `${lane.origin} → ${lane.destination}`,
          });
        }

        return acc;
      }, []) ?? [],
    [selectedCoveragePlan]
  );

  const coveragePlanLaneEquipmentOptions = useMemo(
    () =>
      selectedCoveragePlanLane?.equipment.map((equipment) => ({
        key: equipment.id,
        value: equipment.id,
        text: equipment.name,
      })) ?? [],
    [selectedCoveragePlanLane]
  );

  return (
    <Form widths="equal" className={styles.carrierPlanCapacityForm}>
      <Form.Group>
        <Controller
          name="planId"
          control={props.control}
          rules={{
            validate: (value) => typeof value === "number" && value > 0,
          }}
          render={({ field }) => {
            return (
              <Form.Field>
                <label htmlFor={field.name}>Coverage Plan</label>
                <Dropdown
                  {...field}
                  ref={(element: SemanticElement<DropdownProps>) => {
                    if (element) {
                      element.focus = element.handleFocus;
                    }
                    field.ref(element);
                  }}
                  id={field.name}
                  aria-label="Coverage Plan"
                  required
                  selection
                  search
                  clearable
                  placeholder="Select"
                  options={coveragePlanOptions}
                  value={field.value ?? ""}
                  onChange={(_event, { value }) => {
                    field.onChange(value || null);
                    const selectedPlan = coveragePlans.find(
                      (plan) => plan.id === value
                    );
                    if (selectedPlan?.lanes.length === 1) {
                      props.setValue("laneId", selectedPlan.lanes[0].id);
                      props.setValue(
                        "equipment",
                        selectedPlan.lanes[0].equipment.length === 1
                          ? selectedPlan.lanes[0].equipment[0]
                          : null
                      );
                    } else {
                      props.setValue("laneId", null);
                      props.setValue("equipment", null);
                    }
                  }}
                  error={!!props.errors.planId}
                />
              </Form.Field>
            );
          }}
        />

        <Controller
          name="laneId"
          control={props.control}
          rules={{ required: coveragePlanLaneOptions.length > 1 }}
          render={({ field }) => {
            return (
              <Form.Field>
                <label htmlFor={field.name}>Coverage Plan Lane</label>
                <Dropdown
                  {...field}
                  id={field.name}
                  aria-label="Coverage Plan Lane"
                  required={coveragePlanLaneOptions.length > 1}
                  ref={(element: SemanticElement<DropdownProps>) => {
                    if (element) {
                      element.focus = element.handleFocus;
                    }
                    field.ref(element);
                  }}
                  selection
                  search
                  clearable
                  disabled={coveragePlanLaneOptions.length < 2}
                  placeholder="Select"
                  options={coveragePlanLaneOptions}
                  value={field.value ?? ""}
                  onChange={(_event, { value }) => {
                    field.onChange(value || null);
                    const selectedLane = selectedCoveragePlan?.lanes.find(
                      (lane) => lane.id === value
                    );
                    props.setValue(
                      "equipment",
                      selectedLane?.equipment.length === 1
                        ? selectedLane.equipment[0]
                        : null
                    );
                  }}
                  error={!!props.errors.laneId}
                />
              </Form.Field>
            );
          }}
        />
      </Form.Group>

      {props.defaultValues.type === "CoveragePlan" ? (
        <>
          <h4>Plan Details</h4>

          <Segment>
            <Form.Group>
              <Form.Field>
                <label htmlFor="customer">Customer</label>
                <Input
                  name="customer"
                  id="customer"
                  disabled
                  value={selectedCoveragePlan?.customer?.name ?? ""}
                />
              </Form.Field>

              <Form.Field>
                <label htmlFor="volumeStartDate">Volume Start Date</label>
                <Input
                  name="volumeStartDate"
                  id="volumeStartDate"
                  type="date"
                  disabled
                  value={
                    selectedCoveragePlan
                      ? convertToFormDateString(
                          selectedCoveragePlan.volumeStartDate
                        )
                      : ""
                  }
                />
              </Form.Field>
              <Form.Field>
                <label htmlFor="targetRate">Target Rate</label>
                <Input
                  name="targetRate"
                  id="targetRate"
                  value={selectedCoveragePlanLane?.targetRate ?? ""}
                  labelPosition="left"
                  disabled
                  label={{ basic: true, content: "$" }}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group>
              <Form.Field>
                <label htmlFor="planNote">Custom Plan Notes</label>
                <TextArea
                  name="planNote"
                  id="planNote"
                  value={selectedCoveragePlan?.note ?? ""}
                  rows={selectedCoveragePlan?.note ? 4 : 1}
                  disabled
                />
              </Form.Field>
            </Form.Group>
          </Segment>

          <Form.Group>
            <Controller
              name="equipment"
              control={props.control}
              rules={{ required: coveragePlanLaneEquipmentOptions.length > 1 }}
              render={({ field }) => {
                return (
                  <Form.Field>
                    <label htmlFor={field.name}>Lane Equipment</label>
                    <Dropdown
                      {...field}
                      ref={(element: SemanticElement<DropdownProps>) => {
                        if (element) {
                          element.focus = element.handleFocus;
                        }
                        field.ref(element);
                      }}
                      id={field.name}
                      aria-label="Lane Equipment"
                      required={coveragePlanLaneEquipmentOptions.length > 1}
                      selection
                      search
                      clearable
                      disabled={coveragePlanLaneEquipmentOptions.length < 2}
                      placeholder="Select"
                      options={coveragePlanLaneEquipmentOptions}
                      value={field.value?.id ?? ""}
                      onChange={(_event, { value }) => {
                        const equipment = coveragePlanLaneEquipmentOptions.find(
                          (option) => option.value === value
                        );
                        field.onChange(
                          equipment
                            ? { id: equipment.value, name: equipment.text }
                            : null
                        );
                      }}
                      error={!!props.errors.equipment}
                    />
                  </Form.Field>
                );
              }}
            />

            <Controller
              name="coverageAssigned"
              control={props.control}
              rules={{
                required: true,
                min: 1,
                validate: (value) => Number.isInteger(Number(value)),
              }}
              render={({ field }) => (
                <Form.Field>
                  <label htmlFor={field.name}>
                    Expected Equipment Available
                  </label>
                  <Input
                    {...field}
                    id={field.name}
                    required
                    error={!!props.errors.coverageAssigned}
                    label={{
                      basic: true,
                      content: selectedCoveragePlan?.frequency ?? "Daily",
                    }}
                    onChange={(_event, { value }) =>
                      field.onChange(integerReplace(value))
                    }
                    labelPosition="right"
                  />
                </Form.Field>
              )}
            />

            <Controller
              name="rate"
              control={props.control}
              rules={{
                min: 1,
                validate: (value) => Number.isInteger(Number(value)),
              }}
              render={({ field }) => (
                <Form.Field>
                  <label htmlFor={field.name}>Rate</label>
                  <Input
                    {...field}
                    id={field.name}
                    error={!!props.errors.rate}
                    labelPosition="left"
                    onChange={(_event, { value }) =>
                      field.onChange(integerReplace(value))
                    }
                    label={{ basic: true, content: "$" }}
                  />
                </Form.Field>
              )}
            />
          </Form.Group>
        </>
      ) : null}

      <Form.Group>
        <Controller
          name="note"
          control={props.control}
          render={({ field }) => {
            return (
              <Form.Field>
                <label htmlFor={field.name}>Note</label>
                <Input
                  {...field}
                  id={field.name}
                  maxLength={500}
                  error={!!props.errors.note}
                />
              </Form.Field>
            );
          }}
        />
      </Form.Group>
    </Form>
  );
};

export default CarrierPlanCapacityForm;
