import cx from "classnames";
import { Button, Icon, Loader, Table } from "semantic-ui-react";

import MarketplaceCarrierIcon from "components/MarketplaceIcon/MarketplaceCarrierIcon";
import CarrierLabelBadge from "../CarrierLabelBadge/CarrierLabelBadge";
import { IS_EU } from "shared/constants/envConstants";
import { CarrierListRowCellsSizeProps } from "./CarrierListRow";

import styles from "../CarrierList.module.scss";

const CarrierListRowCellsLarge = (props: CarrierListRowCellsSizeProps) => {
  return (
    <>
      <Table.Cell collapsing>
        <Button
          type="button"
          size="tiny"
          color="blue"
          onClick={props.handleCarrierEdit}
        >
          View / Edit
        </Button>

        {props.isLoadingStarredStatus ? (
          <Loader active inline size="mini" className={styles.sizedIcon} />
        ) : (
          <Icon
            name="star"
            link
            className={styles.sizedIcon}
            color={props.carrier.isFavoritedByCurrentUser ? "yellow" : "grey"}
            onClick={props.handleCarrierStar}
          />
        )}
      </Table.Cell>

      <Table.Cell collapsing>
        <CarrierLabelBadge {...props.carrier} />
      </Table.Cell>

      <Table.Cell className={cx(styles.carrierNameCell, styles.truncate)}>
        <div
          className={styles.carrierName}
          title={props.cellValues.carrierName}
        >
          {props.cellValues.carrierName}
        </div>
        <div
          title={props.cellValues.carrierOwner}
          className={cx(
            styles.grey,
            props.isOwnedByCurrentUser ? styles.isCurrentUser : null
          )}
        >
          Owner: {props.cellValues.carrierOwner}
        </div>
      </Table.Cell>

      <Table.Cell className={styles.truncate}>
        {props.cellValues.mcNumber}
      </Table.Cell>

      <Table.Cell className={styles.truncate}>
        {props.cellValues.dotNumber}
      </Table.Cell>

      <Table.Cell className={styles.truncate}>
        {props.cellValues.classification}
      </Table.Cell>

      <Table.Cell className={styles.truncate}>
        {props.cellValues.phone}
      </Table.Cell>

      <Table.Cell className={styles.truncate} title={props.cellValues.email}>
        {props.cellValues.email}
      </Table.Cell>

      <Table.Cell className={styles.truncate}>
        {props.cellValues.address}
      </Table.Cell>

      <Table.Cell className={styles.truncate}>
        {props.cellValues.lastActivityTime}
      </Table.Cell>

      <Table.Cell className={styles.truncate}>
        <div title={props.cellValues.lastActivitySummary}>
          {props.cellValues.lastActivitySummary}
        </div>
        <div title={props.cellValues.lastActivityNote} className={styles.grey}>
          {props.cellValues.lastActivityNote}
        </div>
      </Table.Cell>

      <Table.Cell collapsing>
        {!IS_EU ? <MarketplaceCarrierIcon profile={props.carrier} /> : null}
      </Table.Cell>
    </>
  );
};

export default CarrierListRowCellsLarge;
