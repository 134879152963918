import useSWR from "swr";
import axios, { AxiosError } from "axios";

import { Option } from "models/Option";
import { EquipmentModeCategoryDto } from "models/dto/EquipmentModeCategoryDto";
import { EquipmentModeDto } from "models/dto/EquipmentModeDto";
import { AttributeGroupDto } from "models/dto/AttributeGroupDto";
import { getUniqueOptions } from "utils/getUniqueOptions";

export type CarriersOptions = {
  callActivityOptions: Option<string>[];
  usStateOptions: Option<string>[];
  caProvinceOptions: Option<string>[];
  mxStateOptions: Option<string>[];
  equipmentOptions: Option<string>[];
  trailerOptions: Option<number>[];
  attributesOptions: Option<string>[];
  authoritiesOptions: Option<string>[];
};

export type GetCarriersOptions = {
  equipmentTypes: EquipmentModeDto[];
  trailerTypes: EquipmentModeCategoryDto[];
  attributes: AttributeGroupDto[];
  usStates: string[];
  caProvinces: string[];
  mxStates: string[];
  callActivityOptions: ("Called Today" | "Not Called Today" | "Both")[];
};

export const useGetCarriersOptions = () => {
  const { data, error, isLoading } = useSWR<GetCarriersOptions, AxiosError>(
    "/api/carriers/options",
    (url) =>
      axios.get<GetCarriersOptions>(url).then((response) => response.data),
    { keepPreviousData: true, revalidateOnFocus: false }
  );

  return {
    data: data
      ? mapCarriersOptionsToOptions(data)
      : getDefaultCarriersOptions(),
    error,
    isLoading,
  };
};

const getDefaultCarriersOptions = (): CarriersOptions => ({
  callActivityOptions: [],
  usStateOptions: [],
  caProvinceOptions: [],
  mxStateOptions: [],
  equipmentOptions: [],
  trailerOptions: [],
  attributesOptions: [],
  authoritiesOptions: [],
});

export const mapCarriersOptionsToOptions = (
  data: GetCarriersOptions
): CarriersOptions => ({
  trailerOptions: mapEquipmentToOptions(data.trailerTypes),
  equipmentOptions: mapEquipmentToOptions(data.equipmentTypes),
  usStateOptions: mapCountryStatesToOptions(data.usStates, "US"),
  caProvinceOptions: mapCountryStatesToOptions(data.caProvinces, "CA"),
  mxStateOptions: mapCountryStatesToOptions(data.mxStates, "MX"),
  callActivityOptions: mapCallActivityToOptions(data.callActivityOptions),
  attributesOptions: mapAttributesToOptions(data.attributes, [
    "Attributes",
    "Admin approved",
  ]),
  authoritiesOptions: mapAttributesToOptions(data.attributes, ["Authorities"]),
});

const mapEquipmentToOptions = <
  T extends EquipmentModeDto | EquipmentModeCategoryDto,
>(
  data: T[]
): Option<T["id"]>[] =>
  data.map((equipment) => ({
    key: equipment.id,
    value: equipment.id,
    text: equipment.text,
  }));

const mapCountryStatesToOptions = (
  data: string[],
  country: string
): Option<string>[] =>
  data.map((state) => ({
    key: `${country},${state}`,
    value: `${country},${state}`,
    text: `${state}`,
  }));

const mapCallActivityToOptions = (data: string[]): Option<string>[] =>
  data.map((callActivity) => ({
    key: callActivity,
    value: callActivity,
    text: callActivity,
  }));

export const mapAttributesToOptions = (
  data: AttributeGroupDto[],
  groupNames: string[]
): Option<string>[] =>
  getUniqueOptions(
    data
      .filter((group) => groupNames.includes(group.attributeGroup))
      .reduce<Option<string>[]>((acc, { attributes }) => {
        attributes.forEach((attribute) => {
          acc.push({
            key: `${attribute.id},${attribute.attributeType}`,
            value: `${attribute.id},${attribute.attributeType}`,
            text: attribute.attributeName,
          });
        });
        return acc;
      }, [])
  );
