import { useState } from "react";
import { Button, Table } from "semantic-ui-react";

import CarrierPlanCapacityTableRow from "components/CarrierCapacity/tables/CarrierPlanCapacityTable/CarrierPlanCapacityTableRow";
import { useGetCarrierPlanCapacities } from "components/CarrierCapacity/hooks/useGetCarrierPlanCapacities";
import CarrierPlanCapacityFormModal from "../../forms/CarrierPlanCapacityForm/CarrierPlanCapacityFormModal";
import { getDefaultCarrierPlanCapacityFormObject } from "../../forms/CarrierPlanCapacityForm/utils/carrierPlanCapacityDefaultFormObjects";
import CarrierCapacityLoadingTableRow from "../CarrierCapacityLoadingTableRow";
import CarrierCapacityNoCapacitiesTableRow from "../CarrierCapacityNoCapacitiesTableRow";
import { capacityNoteText } from "shared/constants/activityConstants";
import { SelectedCarrier } from "models/SelectedCarrier";

type CarrierPlanCapacityTableProps = {
  carrier: SelectedCarrier;
  updateCarrierTimeline: (note: string) => Promise<void>;
  refreshCarrierTimeline: () => void;
};

const CarrierPlanCapacityTable = (props: CarrierPlanCapacityTableProps) => {
  const [isAddingPlanCapacity, setIsAddingPlanCapacity] = useState(false);
  const [isAddingNoPlanCapacity, setIsAddingNoPlanCapacity] = useState(false);
  const {
    data: planCapacities,
    isLoading,
    mutate: reloadCapacities,
  } = useGetCarrierPlanCapacities({
    carrierId: props.carrier.id,
  });

  const reloadCarrierData = () => {
    props.refreshCarrierTimeline();
    reloadCapacities();
  };

  const getTableRows = () => {
    if (isLoading) {
      return <CarrierCapacityLoadingTableRow columns={8} />;
    } else if (planCapacities?.length) {
      return planCapacities.map((planCapacity) => (
        <CarrierPlanCapacityTableRow
          key={planCapacity.id}
          planCapacity={planCapacity}
          carrier={props.carrier}
          reloadCarrierData={reloadCarrierData}
          updateCarrierTimeline={props.updateCarrierTimeline}
        />
      ));
    } else {
      return <CarrierCapacityNoCapacitiesTableRow columns={8} />;
    }
  };

  return (
    <div>
      <header>
        <div>
          <h2>Plan Capacity</h2>
        </div>
        <div>
          <Button primary onClick={() => setIsAddingPlanCapacity(true)}>
            Add Plan Capacity
          </Button>
          <Button color="red" onClick={() => setIsAddingNoPlanCapacity(true)}>
            No Plan Capacity
          </Button>
        </div>
      </header>

      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
            <Table.HeaderCell>Plan Name</Table.HeaderCell>
            <Table.HeaderCell>Plan Lane</Table.HeaderCell>
            <Table.HeaderCell>Equipment</Table.HeaderCell>
            <Table.HeaderCell>Trucks</Table.HeaderCell>
            <Table.HeaderCell>Rate</Table.HeaderCell>
            <Table.HeaderCell>Note</Table.HeaderCell>
            <Table.HeaderCell>User Entered</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{getTableRows()}</Table.Body>
      </Table>

      {isAddingPlanCapacity ? (
        <CarrierPlanCapacityFormModal
          onClose={() => setIsAddingPlanCapacity(false)}
          defaultValues={getDefaultCarrierPlanCapacityFormObject(
            "CoveragePlan"
          )}
          carrier={props.carrier}
          onSubmitCallback={reloadCarrierData}
        />
      ) : null}

      {isAddingNoPlanCapacity ? (
        <CarrierPlanCapacityFormModal
          onClose={() => setIsAddingNoPlanCapacity(false)}
          defaultValues={getDefaultCarrierPlanCapacityFormObject(
            "NoPlanCapacity"
          )}
          carrier={props.carrier}
          onSubmitCallback={() => {
            props
              .updateCarrierTimeline(
                `${props.carrier.name} ${capacityNoteText.noPlanCapacityCreate}`
              )
              .then(() => reloadCarrierData());
          }}
        />
      ) : null}
    </div>
  );
};

export default CarrierPlanCapacityTable;
