import * as constants from "../constants/validationErrorMessages";
import {
  operationDaysOptionsList,
  operationDaysType,
  operationHoursOptionsList,
  operationHoursType,
} from "shared/constants";
import momentTz from "moment-timezone";
import { PhoneData } from "../../models/Phone";
import { CarriersListResponse } from "models/dto/CarriersListResponse";

interface FormField<T> {
  value: T;
  validation?: any; // Adjust the type according to your needs
  valid?: { isValid: boolean; errorMessage: string | null }; // Adjust the type according to your needs
  touched?: boolean;
}

// Define a generic type for the form object
type Form<T> = Record<string, FormField<T>>;

export const updateObject = <T extends object, U extends object>(
  oldObject: T,
  updatedProperties?: U
): T & U => {
  if (!updatedProperties) {
    return { ...oldObject } as T & U;
  }

  return {
    ...oldObject,
    ...updatedProperties,
  };
};

export function validate<T>(form: Form<T>): Form<T> {
  for (const el in form) {
    if (Object.prototype.hasOwnProperty.call(form, el)) {
      const field = form[el];

      if (field.validation) {
        const validationResult = checkValidity(field.value, field.validation);

        field.valid = {
          isValid: validationResult.isValid,
          errorMessage: validationResult.errorMessage,
        };
        field.touched = true;
      } else {
        field.valid = { isValid: true, errorMessage: null };
        field.touched = true;
      }
    }
  }

  return form;
}

export const checkValidity = (value: any, rules: any) => {
  let isValid = true;

  if ((value === null || typeof value === "undefined") && rules?.required) {
    return {
      isValid: false,
      errorMessage: constants.REQUIRED_ERROR_MESSAGE,
    };
  }

  if (!rules) {
    return {
      isValid: true,
      errorMessage: "",
    };
  }

  if (rules.required) {
    isValid =
      (value.toString().trim() !== "" || value.toString().trim() !== "") &&
      value !== "none" &&
      isValid;

    if (!isValid) {
      return {
        isValid: false,
        errorMessage: constants.REQUIRED_ERROR_MESSAGE,
      };
    }
  } else if (value === null || value === undefined || value === "") {
    return {
      isValid: true,
      errorMessage: null,
    };
  }

  if (rules.pattern) {
    isValid = value === "" || rules.pattern.test(value);

    if (!isValid) {
      return {
        isValid: false,
        errorMessage: constants.FORMAT_ERROR_MESSAGE,
      };
    }
  }

  if (rules.length) {
    isValid = value.toString().length === rules.length && isValid;

    if (!isValid) {
      return {
        isValid: false,
        errorMessage: `${constants.LENGTH_ERROR_MESSAGE} ${rules.length}`,
      };
    }
  }

  if (rules.minLength) {
    isValid = value.toString().length >= rules.minLength && isValid;

    if (!isValid) {
      return {
        isValid: false,
        errorMessage: `${constants.MIM_LENGTH_ERROR_MESSAGE} ${
          value.toString().length
        }`,
      };
    }
  }

  if (rules.maxLength) {
    isValid = value.toString().length <= rules.maxLength && isValid;

    if (!isValid) {
      return {
        isValid: false,
        errorMessage: constants.MAX_LENGTH_ERROR_MESSAGE,
      };
    }
  }

  if (rules.minAddressLength) {
    isValid = value.toString().length >= rules.minAddressLength && isValid;

    if (!isValid) {
      return {
        isValid: false,
        errorMessage: `${constants.ADDRESS_ERROR_MESSAGE} ${rules.minAddressLength}`,
      };
    }
  }
  if (rules.maxNumberValue) {
    isValid = value <= rules.maxNumberValue && isValid;

    if (!isValid) {
      return {
        isValid: false,
        errorMessage: `${constants.MAX_VALUE_ERROR_MESSAGE} (${rules.maxNumberValue})`,
      };
    }
  }

  if (rules.minNumberValue) {
    isValid = value >= rules.minNumberValue && isValid;

    if (!isValid) {
      return {
        isValid: false,
        errorMessage: `${constants.MIN_VALUE_ERROR_MESSAGE} (${rules.minNumberValue})`,
      };
    }
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid;

    if (!isValid) {
      return {
        isValid: false,
        errorMessage: constants.IS_NUMERIC_ERROR_MESSAGE,
      };
    }
  }

  if (rules.isDouble) {
    const pattern = /^\d{0,2}(\.\d{0,2})?$/;
    isValid = pattern.test(value) && isValid;

    if (!isValid) {
      return {
        isValid: false,
        errorMessage: constants.DOUBLE_SEPARATOR_ERROR_MESSAGE,
      };
    }
  }

  return {
    isValid: true,
    errorMessage: "",
  };
};

export const formatActionAddress = (address: {
  street: string;
  city: string;
  zip: string;
  state: string;
  country: string;
}) => {
  const getPropertyValueOrEmptyString = (propVal: string) => {
    return propVal ?? "";
  };

  return (
    `${getPropertyValueOrEmptyString(address.street).toLowerCase()} ` +
    `${getPropertyValueOrEmptyString(address.city).toLowerCase()},` +
    ` ${getPropertyValueOrEmptyString(
      address.zip
    )}, ${getPropertyValueOrEmptyString(
      address.state
    )}, ${getPropertyValueOrEmptyString(address.country)}`
  );
};

export const formatPhoneNumber = (phone: { phoneNumber: string | number }) => {
  const cleaned = ("" + phone.phoneNumber).replace(/\D/g, "");
  const match = cleaned.match(/^(?=\d)(1)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return ["(", match[2], ") ", match[3], "-", match[4]].join("");
  }

  return null;
};

export const formatPhoneNumberWithExtention = (phone: PhoneData) => {
  const cleaned = ("" + phone.phoneNumber).replace(/\D/g, "");
  const match = cleaned.match(/^(?=\d)(1|)?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    if (phone.extension) {
      return [
        "(",
        match[2],
        ") ",
        match[3],
        "-",
        match[4] + " x" + phone.extension,
      ].join("");
    } else {
      return ["(", match[2], ") ", match[3], "-", match[4]].join("");
    }
  }

  return null;
};

export const formatPhoneNumberWithExtentionAndCountryCode = ({
  phoneNumber,
  countryCode,
  extension,
}: {
  phoneNumber: string;
  countryCode: string;
  extension: string;
}) => {
  const cleaned = ("" + phoneNumber).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{3,4})$/);
  const countryCodeVal =
    !(countryCode === null || countryCode === undefined) && countryCode !== ""
      ? `${countryCode} `
      : "";

  if (match) {
    if (extension) {
      return [
        countryCodeVal,
        match[1],
        "-",
        match[2],
        "-",
        match[3] + " x" + extension,
      ].join("");
    } else {
      return [countryCodeVal, match[1], "-", match[2], "-", match[3]].join("");
    }
  }

  return [countryCodeVal, phoneNumber, extension ? `x${extension}` : null].join("");
};

export const remove = <T>(arr: T[], value: T): T[] => {
  const index = arr.indexOf(value);
  if (index !== -1) {
    const newArr = [...arr];
    newArr.splice(index, 1);
    return newArr;
  }
  return arr;
};

export const moneyFormatter = (currency: string) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  });

export const generateIcon = (item: CarriersListResponse) => {
  if (item.name && item.name !== "") {
    const desired = item.name.replace(/[^\w\s]/gi, "");
    const acronym = desired.split(/\s/);

    let result = acronym[0].slice(0, 1);

    if (acronym.length > 1) {
      result += acronym[1].slice(0, 1);
    } else {
      result += acronym[0].slice(1, 2);
    }

    return result.toUpperCase();
  } else {
    return "N/A";
  }
};

export const formatNumberWithCommas = (x: number) => {
  if (!x) return "0";

  const parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return parts.join(".");
};

export const formatLocationHours = (operationHours: {
  customDays: string;
  customHours: string;
  dayType: number;
  hourType: number;
}) => {
  const daysOption = operationDaysOptionsList.find(
    (o: { key: number; text: string }) => o.key === operationHours.dayType
  );
  const hoursOption = operationHoursOptionsList.find(
    (o: { key: number; text: string }) => o.key === operationHours.hourType
  );

  let days = daysOption ? daysOption.text : "";
  let hours = hoursOption ? hoursOption.text : "";

  if (operationHours.dayType.toString() === operationDaysType.CustomDays) {
    days = operationHours.customDays;
  }

  if (operationHours.hourType.toString() === operationHoursType.CustomHours) {
    hours = operationHours.customHours;
  }

  return `${days}, ${hours}`;
};

export const openUrlInNewTab = (url: string | undefined) => {
  if (!url) return;

  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export function replacePathParamInUrl(
  url?: string,
  ...args: (string | undefined | null)[]
): string {
  let count = -1;
  return url?.replace(/:[a-zA-Z?]+/g, (match: string) => {
    count += 1;
    return args[count] ?? match;
  }) ?? "";
}

export const formatDate = (date: Date | string) => {
  if (date) {
    return momentTz(date).format("MM/DD/YYYY");
  }

  return date;
};

export const removeSpecialCharactersIfNumeric = (inputString: string) => {
  // Check if the string consists of numbers and specified characters
  if (/^[()\s_\-0-9]+$/.test(inputString)) {
    // If yes, remove specified characters
    return inputString.replace(/[()\s_-]/g, "");
  } else {
    // If not, return the original string
    return inputString;
  }
};
