import axios from "axios";
import { toast } from "react-toastify";
import { CarrierEditOptionsDto } from "models/dto/CarrierEditOptionsDto";
import { CarrierDto } from "models/dto/CarrierDto";
import { CarrierAttributeDto } from "models/dto/CarrierAttributeDto";
import { GetProfilesDocumentPageQuery } from "models/dto/GetProfilesDocumentPageQuery";

type FilterState = {
  dir: string;
  name: string;
  page: number;
  pageSize: number;
  sortField: string;
  total: number;
};

export const fetchCarrierById = (carrierId: string) => {
  return axios.get<CarrierDto>(`/api/carriers/${carrierId}`);
};

export const starCarrier = (carrierId: string) => {
  return axios.post(`/api/carriers/${carrierId}/star`, { carrierId });
};

export const fetchCarrierSummary = (carrierId: string) => {
  return axios.get(`/api/carriers/${carrierId}/summary`);
};

export const fetchCarrierOwners = (searchQuery: string) => {
  return axios.get(
    `/api/users/turvo/list?name=${searchQuery}&page=1&pageSize=100`
  );
};

export const fetchCarrierOptions = () => {
  return axios.get<CarrierEditOptionsDto>("/api/carriers/options/edit");
};

export const saveCarrier = async (carrier: CarrierDto) => {
  try {
    await axios.put("/api/carriers", carrier);
    toast.success("Carrier details have been saved!");
  } catch {
    return toast.error("Error occured while saving carrier details!");
  }
};

export const fetchCarrierAttributes = () => {
  return axios.get(`/api/carriers/attributes`);
};

export const saveCarrierAttributes = async ({
  carrierId,
  attributeList,
}: {
  carrierId: string;
  attributeList: CarrierAttributeDto[];
}) => {
  try {
    await axios.put(`/api/carriers/${carrierId}/attributes`, {
      attributes: attributeList,
    });
    toast.success("Carrier attributes have been saved!");
  } catch (err) {
    return toast.error("Error occured while saving carrier attributes!");
  }
};

export const fetchCarrierHistory = ({
  carrierId,
  gridState,
}: {
  carrierId: string;
  gridState: FilterState;
}) => {
  return axios.get(
    `/api/carrierHistory/list?carrierId=${carrierId}&pageSize=${gridState.pageSize}&pageNumber=${gridState.page}`
  );
};

export const fetchCarrierDocuments = (
  carrierId: number,
  gridState: { pageSize: number; page: number }
) => {
  return axios.get(
    `/api/carriers/${carrierId}/document/list?carrierId=${carrierId}&pageSize=${gridState.pageSize}&pageNumber=${gridState.page}`
  );
};

export const filterCarrierDocuments = (
  carrierId: number,
  gridState: GetProfilesDocumentPageQuery
) => {
  return axios.get(
    `/api/carriers/${carrierId}/document/search?carrierId=${carrierId}`,
    { params: gridState }
  );
};

export const saveDocumentNotes = async (
  carrierId: number,
  notes: {
    id: number;
    notes: string;
  }
) => {
  try {
    await axios.put(`/api/carriers/${carrierId}/document/notes`, notes);
    toast.success("Document notes have been saved!");
  } catch (err) {
    return toast.error("Error occured while saving document notes!");
  }
};

export const updateCarrierDocument = (
  carrierId: number,
  formData: FormData
) => {
  return axios.put(`/api/carriers/${carrierId}/document`, formData);
};

export const addCarrierDocument = (carrierId: number, formData: FormData) => {
  return axios.post(`/api/carriers/${carrierId}/document`, formData);
};

export const deleteCarrierDocument = async (
  carrierId: number,
  documentId: number
) => {
  try {
    await axios.delete(`/api/carriers/${carrierId}/document/${documentId}`);
    toast.success("Document has been deleted!");
  } catch (err) {
    return toast.error("Error occured while deleting document!");
  }
};
