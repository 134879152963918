import axios from "axios";
import { toast } from "react-toastify";
import { Customer, CustomerAttributes } from "../models/Customer";
import { GetCustomersPageQuery } from "models/dto/GetCustomersPageQuery";
import { GetProfilesDocumentPageQuery } from "models/dto/GetProfilesDocumentPageQuery";

export const fetchCustomerById = (customerId: number) => {
  return axios.get(`/api/customers/${customerId}`);
};

export const updateCustomer = (customer: Customer) => {
  return axios
    .put(`/api/customers/${customer.id}`, customer)
    .then(() => {
      toast.success("Customer details have been saved!");
    })
    .catch(() => toast.error("Error occured while saving customer details!"));
};

export const uploadCustomerImage = (
  customerId: number,
  formData: { binary: string }
) => {
  return axios.post(`/api/customers/${customerId}/images`, formData);
};

export const deleteCustomerImage = (customerId: number) => {
  return axios.delete(`/api/customers/${customerId}/images`);
};

export const fetchCustomerOptionsData = () => {
  return axios.get(`/api/customers/options/edit`);
};

export const fetchCustomerAttributes = () => {
  return axios.get(`/api/customers/attributes`);
};

export const updateCustomerAttributes = (
  customerId: number,
  attributeList: CustomerAttributes[]
) => {
  return axios
    .put(`/api/customers/${customerId}/attributes`, attributeList)
    .then(() => {
      toast.success("Customer attributes have been saved!");
    })
    .catch(() =>
      toast.error("Error occured while saving customer attributes!")
    );
};

export const fetchCustomerEmails = (customerId: number) => {
  return axios.get(`/api/customers/${customerId}/emails/list`);
};

export const fetchCustomerAddresses = (customerId: number) => {
  return axios.get(`/api/customers/${customerId}/addresses/list`);
};

export const fetchCustomerPhones = (customerId: number) => {
  return axios.get(`/api/customers/${customerId}/phones/list`);
};

export const fetchCustomerList = ({
  gridState,
}: {
  gridState: GetCustomersPageQuery;
}) => {
  return axios.get("/api/customers/list", { params: gridState });
};

export const fetchCustomerDocuments = (
  customerId: number,
  gridState: { pageSize: number; page: number }
) => {
  return axios.get(
    `/api/customers/${customerId}/document/list?customerId=${customerId}&pageSize=${gridState.pageSize}&pageNumber=${gridState.page}`
  );
};

export const filterCustomerDocuments = (
  customerId: number,
  gridState: GetProfilesDocumentPageQuery
) => {
  return axios.get(
    `/api/customers/${customerId}/document/search?customerId=${customerId}`,
    { params: gridState }
  );
};

export const saveDocumentNotes = (
  customerId: number,
  notes: { id: number; notes: string }
) => {
  return axios
    .put(`/api/customers/${customerId}/document/notes`, notes)
    .then(() => {
      toast.success("Document notes have been saved!");
    })
    .catch(() => toast.error("Error occured while saving document notes!"));
};

export const addCustomerDocument = (customerId: number, formData: FormData) => {
  return axios.post(`/api/customers/${customerId}/document`, formData);
};

export const updateCustomerDocument = (
  customerId: number,
  formData: FormData
) => {
  return axios.put(`/api/customers/${customerId}/document`, formData);
};

export const deleteCustomerDocument = (
  customerId: number,
  documentId: number
) => {
  return axios
    .delete(`/api/customers/${customerId}/document/${documentId}`)
    .then(() => {
      toast.success("Document have been deleted!");
    })
    .catch(() => toast.error("Error occured while deleting document!"));
};
