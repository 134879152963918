import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "api/carrierAPI";
import { GetProfilesDocumentPageQuery } from "models/dto/GetProfilesDocumentPageQuery";
import { ProfilesDocumentDto } from "models/dto/ProfilesDocumentDto";
import { QueryGridResponse } from "models/QueryGridResponse";
import { toast } from "react-toastify";

type CarrierIdGridState = {
  carrierId: number;
  gridState: GetProfilesDocumentPageQuery;
};

type CarrierDocumentsInitialState = {
  list: QueryGridResponse<ProfilesDocumentDto>;
  exportFileUrl: string;
  loading: boolean;
  error: string | null;
  editingItem: string;
  gridState: GetProfilesDocumentPageQuery & { total?: number };
};

const fetchCarrierDocuments = createAsyncThunk<
  QueryGridResponse<ProfilesDocumentDto>,
  CarrierIdGridState
>("carrierDocuments/fetch", async ({ carrierId, gridState }) => {
  const response = await API.fetchCarrierDocuments(carrierId, gridState);
  return response.data;
});

const filterCarrierDocuments = createAsyncThunk<
  QueryGridResponse<ProfilesDocumentDto>,
  CarrierIdGridState
>("carrierDocuments/filter", async ({ carrierId, gridState }) => {
  const response = await API.filterCarrierDocuments(carrierId, gridState);
  return response.data;
});

const saveDocumentNotes = createAsyncThunk<
  void,
  CarrierIdGridState & {
    notes: {
      id: number;
      notes: string;
    };
  }
>(
  "carrierDocuments/saveNote",
  async ({ carrierId, notes, gridState }, thunkAPI) => {
    await API.saveDocumentNotes(carrierId, notes);
    thunkAPI.dispatch(filterCarrierDocuments({ carrierId, gridState }));
  }
);

const addCarrierDocument = createAsyncThunk<
  void,
  CarrierIdGridState & { formData: FormData }
>(
  "addCarrierDocument/addDocument",
  async ({ carrierId, formData, gridState }, thunkAPI) => {
    const response = await API.addCarrierDocument(carrierId, formData);
    if (response) {
      toast.success("New document added successfully!");
      thunkAPI.dispatch(filterCarrierDocuments({ carrierId, gridState }));
    }
  }
);

const updateCarrierDocument = createAsyncThunk<
  void,
  CarrierIdGridState & { formData: FormData }
>(
  "updateCarrierDocument/saveDocument",
  async ({ carrierId, formData, gridState }, thunkAPI) => {
    const response = await API.updateCarrierDocument(carrierId, formData);
    if (response) {
      toast.success("Document details updated successfully!");
      thunkAPI.dispatch(filterCarrierDocuments({ carrierId, gridState }));
    }
  }
);

const deleteCarrierDocument = createAsyncThunk<
  void,
  CarrierIdGridState & { documentId: number }
>(
  "deleteCarrierDocument/removeDocument",
  async ({ carrierId, documentId, gridState }, thunkAPI) => {
    await API.deleteCarrierDocument(carrierId, documentId);
    thunkAPI.dispatch(filterCarrierDocuments({ carrierId, gridState }));
  }
);

const initialState: CarrierDocumentsInitialState = {
  list: {
    filteredCount: 0,
    entities: [],
  },
  loading: false,
  error: "",
  exportFileUrl: "",
  editingItem: "",
  gridState: {
    page: 1,
    pageSize: 10,
    total: 0,
    sortField: "name",
    dir: "ASC",
    name: "",
  },
};

export const carrierDocumentsSlice = createSlice({
  name: "carrierDocuments",
  initialState,
  reducers: {
    resetDocuments: (state) => {
      state.list = { ...initialState.list };
    },
    addNewDocumentInfo: (state) => {
      const newDocumentInfoItem = {
        id: 0,
      } as ProfilesDocumentDto;
      state.list.entities.unshift(newDocumentInfoItem);
    },
    removeNewDocumentInfo: (state) => {
      state.list.entities.shift();
    },
    setEditingDocument: (state, action) => {
      state.editingItem = action.payload;
    },
    updateCarrierGridState: (state, action) => {
      state.gridState = { ...state.gridState, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCarrierDocuments.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCarrierDocuments.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action.payload;
    });
    builder.addCase(fetchCarrierDocuments.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(saveDocumentNotes.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(saveDocumentNotes.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(saveDocumentNotes.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(filterCarrierDocuments.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(filterCarrierDocuments.fulfilled, (state, action) => {
      state.loading = false;
      state.list = action.payload;
    });
    builder.addCase(filterCarrierDocuments.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateCarrierDocument.fulfilled, (state) => {
      state.editingItem = "";
    });
  },
});

export const actions = {
  ...carrierDocumentsSlice.actions,
  fetchCarrierDocuments,
  saveDocumentNotes,
  filterCarrierDocuments,
  updateCarrierDocument,
  deleteCarrierDocument,
  addCarrierDocument,
};

export const reducer = carrierDocumentsSlice.reducer;
