// components
import { Dimmer, Loader } from "semantic-ui-react";

interface Props {
  style?: React.CSSProperties;
  size?:
    | "mini"
    | "tiny"
    | "small"
    | "medium"
    | "large"
    | "big"
    | "huge"
    | "massive";
}

const CustomLoader = ({ style, size = "large" }: Props) => (
  <Dimmer
    title="loader-spinner"
    inverted
    active
    style={{ ...style, background: "rgba(255,255,255,.65)" }}
  >
    <Loader size={size} />
  </Dimmer>
);

export default CustomLoader;
