import {
  Dropdown,
  DropdownProps,
  Form,
  Input,
  TextArea,
} from "semantic-ui-react";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormSetValue,
} from "react-hook-form";

import { Option } from "models/Option";
import { useGetLocationOptions } from "hooks/useGetLocationOptions";
import { integerReplace } from "utils/integerReplace";
import { CarrierCapacityTypeInfoDto } from "models/dto/CarrierCapacityInfoDto";
import { convertToFormDateString } from "./utils/carrierCapacityApiToFormMappers";
import { SemanticElement } from "models/SemanticElement";

export type CarrierCapacityFormObject = {
  id: number | null;
  type: CarrierCapacityTypeInfoDto;
  date: string;
  origin: string;
  destination: string;
  rate: string;
  equipmentModeCategoryId: number | null;
  note: string;
};

type CarrierCapacityFormProps = {
  closeForm: () => void;
  defaultValues: CarrierCapacityFormObject;
  control: Control<CarrierCapacityFormObject>;
  errors: FieldErrors<CarrierCapacityFormObject>;
  setValue: UseFormSetValue<CarrierCapacityFormObject>;
  equipmentOptions: Option<number>[];
  hasOriginOrDestination: boolean;
};

const CarrierCapacityForm = (props: CarrierCapacityFormProps) => {
  const { locationOptions, locationOptionsLoading, debouncedLocationSearch } =
    useGetLocationOptions([
      props.defaultValues.origin,
      props.defaultValues.destination,
    ]);

  const dateRequired =
    props.defaultValues.type === "Capacity" ||
    props.defaultValues.type === "NoCapacity";

  const originRequired =
    props.defaultValues.type === "Capacity" ||
    props.defaultValues.type === "Preferred Lane" ||
    (props.defaultValues.type === "NoCapacity" && props.hasOriginOrDestination);

  const destinationRequired =
    props.defaultValues.type === "Preferred Lane" ||
    (props.defaultValues.type === "NoCapacity" && props.hasOriginOrDestination);

  return (
    <Form>
      <Form.Group widths="equal">
        {dateRequired ? (
          <Controller
            name="date"
            control={props.control}
            rules={{ required: dateRequired }}
            render={({ field }) => (
              <Form.Field required={dateRequired} error={!!props.errors.date}>
                <label htmlFor={field.name}>Date</label>
                <Input
                  {...field}
                  id={field.name}
                  type="date"
                  min={convertToFormDateString(new Date().toISOString())}
                />
              </Form.Field>
            )}
          />
        ) : null}

        <Controller
          name="rate"
          control={props.control}
          rules={{
            min: 1,
            validate: (value) => Number.isInteger(Number(value)),
          }}
          render={({ field }) => (
            <Form.Field error={!!props.errors.rate}>
              <label htmlFor={field.name}>Rate</label>
              <Input
                {...field}
                id={field.name}
                label={{ basic: true, content: "$" }}
                labelPosition="left"
                placeholder="Rate"
                onChange={(_event, { value }) =>
                  field.onChange(integerReplace(value))
                }
              />
            </Form.Field>
          )}
        />

        <Controller
          name="equipmentModeCategoryId"
          control={props.control}
          rules={{ required: true }}
          render={({ field }) => {
            return (
              <Form.Field
                required
                error={!!props.errors.equipmentModeCategoryId}
              >
                <label htmlFor={field.name}>Equipment</label>
                <Dropdown
                  {...field}
                  ref={(element: SemanticElement<DropdownProps>) => {
                    if (element) {
                      element.focus = element.handleFocus;
                    }
                    field.ref(element);
                  }}
                  id={field.name}
                  aria-label="Equipment"
                  placeholder="Search"
                  search
                  selection
                  clearable
                  options={props.equipmentOptions}
                  value={field.value ?? ""}
                  onChange={(_event, { value }) => field.onChange(value)}
                />
              </Form.Field>
            );
          }}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <Controller
          name="origin"
          control={props.control}
          rules={{ required: originRequired }}
          render={({ field }) => {
            return (
              <Form.Field
                required={originRequired}
                error={!!props.errors.origin}
              >
                <label htmlFor={field.name}>Origin</label>
                <Dropdown
                  {...field}
                  ref={(element: SemanticElement<DropdownProps>) => {
                    if (element) {
                      element.focus = element.handleFocus;
                    }
                    field.ref(element);
                  }}
                  id={field.name}
                  aria-label="Origin"
                  selection
                  search
                  clearable
                  placeholder="Search"
                  options={locationOptions}
                  loading={locationOptionsLoading}
                  onChange={(_event, { value }) => {
                    field.onChange(value);
                  }}
                  onSearchChange={(_event, { searchQuery }) =>
                    debouncedLocationSearch(searchQuery.trim())
                  }
                />
              </Form.Field>
            );
          }}
        />

        <Controller
          name="destination"
          control={props.control}
          rules={{ required: destinationRequired }}
          render={({ field }) => {
            return (
              <Form.Field
                required={destinationRequired}
                error={!!props.errors.destination}
              >
                <label htmlFor={field.name}>Destination</label>
                <Dropdown
                  {...field}
                  ref={(element: SemanticElement<DropdownProps>) => {
                    if (element) {
                      element.focus = element.handleFocus;
                    }
                    field.ref(element);
                  }}
                  id={field.name}
                  aria-label="Destination"
                  selection
                  search
                  clearable
                  placeholder="Search"
                  options={locationOptions}
                  loading={locationOptionsLoading}
                  onChange={(_event, { value }) => {
                    field.onChange(value);
                  }}
                  onSearchChange={(_event, { searchQuery }) =>
                    debouncedLocationSearch(searchQuery.trim())
                  }
                />
              </Form.Field>
            );
          }}
        />
      </Form.Group>

      <Form.Group widths="equal">
        <Controller
          name="note"
          control={props.control}
          render={({ field }) => {
            return (
              <Form.Field error={!!props.errors.note}>
                <label htmlFor={field.name}>Note</label>
                <TextArea {...field} id={field.name} rows={3} />
              </Form.Field>
            );
          }}
        />
      </Form.Group>
    </Form>
  );
};

export default CarrierCapacityForm;
