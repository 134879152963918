import styles from "./searchbar.module.scss";
import { Icon } from "semantic-ui-react";
import {
  ChangeEvent,
  KeyboardEvent,
  LegacyRef,
  useCallback,
  useEffect,
  useState,
} from "react";

type SearchBarProps = {
  inputPlaceholder: string;
  onSearch: (value: string) => void;
  autoTypeOfInputSearch?: boolean;
  inputRef?: LegacyRef<HTMLInputElement>;
  disabled?: boolean;
  value?: string;
};

const SearchBar = (props: SearchBarProps) => {
  const { onSearch } = props;

  const [focusInput, setFocusInput] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>(props.value ?? "");

  useEffect(() => {
    setInputValue(props.value ?? "");
  }, [props.value]);

  const searchLoopStyle: string = props.autoTypeOfInputSearch
    ? styles.icon_wrapper_auto_search
    : styles.icon_wrapper;

  const handleOnInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setInputValue(value);
    },
    []
  );

  const handleSearchButtonClick = useCallback(() => {
    onSearch(inputValue);
  }, [inputValue, onSearch]);

  const handleOnInputChangeAutoSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setInputValue(value);
      onSearch(value);
    },
    [onSearch]
  );

  const handleKeyPress = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter" && onSearch) {
        onSearch(inputValue);
      }
    },
    [inputValue, onSearch]
  );

  return (
    <div className={styles.search_wrapper}>
      <input
        ref={props.inputRef}
        type="text"
        placeholder={props.inputPlaceholder}
        onFocus={() => setFocusInput(true)}
        onBlur={() => setFocusInput(false)}
        value={inputValue}
        onChange={
          props.autoTypeOfInputSearch
            ? handleOnInputChangeAutoSearch
            : handleOnInputChange
        }
        onKeyDown={handleKeyPress}
        autoFocus
      />
      <div
        className={`${searchLoopStyle} ${focusInput ? styles.focus_input : ""}`}
        title="search-area"
      >
        <Icon
          link
          name="search"
          disabled={!!props.disabled}
          className={props.disabled ? styles.default_cursor : ""}
          onClick={handleSearchButtonClick}
          title="search-icon"
        />
      </div>
    </div>
  );
};

export default SearchBar;
