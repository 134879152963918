import { toast } from "react-toastify";

import CarrierCapacityTable from "./tables/CarrierCapacityTable/CarrierCapacityTable";
import CarrierPlanCapacityTable from "./tables/CarrierPlanCapacityTable/CarrierPlanCapacityTable";
import CarrierPreferredLaneTable from "./tables/CarrierPreferredLaneTable/CarrierPreferredLaneTable";
import { useGetEquipmentOptions } from "hooks/useGetEquipmentOptions";
import { postCarrierCapacityNote } from "./api/carrierCapacityApi";
import { SelectedCarrier } from "models/SelectedCarrier";
import useGetCarrierActivityTimelineInfinite from "pages/CarrierEdit/hooks/useGetCarrierActivityTimelineInfinite";

import styles from "./CarrierCapacity.module.scss";

type CarrierCapacityProps = {
  carrier: SelectedCarrier;
};

const CarrierCapacity = (props: CarrierCapacityProps) => {
  const { data: equipmentOptions } = useGetEquipmentOptions();
  const { reloadActivities } = useGetCarrierActivityTimelineInfinite({
    carrierId: props.carrier.id,
  });

  const updateCarrierTimeline = async (note: string) => {
    try {
      await postCarrierCapacityNote({
        carrierId: props.carrier.id,
        note,
      });

      toast.success("Carrier timeline updated");
    } catch (error) {
      console.error({ error });
      toast.error("Error updating carrier timeline");
    }
  };

  const childProps = {
    ...props,
    equipmentOptions,
    updateCarrierTimeline,
    refreshCarrierTimeline: reloadActivities,
  };

  return (
    <div className={styles.carrierCapacity}>
      <CarrierCapacityTable {...childProps} />
      <CarrierPlanCapacityTable {...childProps} />
      <CarrierPreferredLaneTable {...childProps} />
    </div>
  );
};

export default CarrierCapacity;
