import cx from "classnames";
import { Button, Icon, Loader, Table } from "semantic-ui-react";

import CarrierTriangleBadge from "../CarrierTriangleBadge/CarrierTriangleBadge";
import { CarrierListRowCellsSizeProps } from "./CarrierListRow";

import styles from "../CarrierList.module.scss";

const CarrierListRowCellsSmall = (props: CarrierListRowCellsSizeProps) => {
  return (
    <>
      <Table.Cell collapsing>
        <CarrierTriangleBadge {...props.carrier} />

        <div>
          <Button
            type="button"
            size="tiny"
            color="blue"
            onClick={props.handleCarrierEdit}
          >
            View / Edit
          </Button>

          {props.isLoadingStarredStatus ? (
            <Loader active inline size="mini" className={styles.sizedIcon} />
          ) : (
            <Icon
              name="star"
              link
              className={styles.sizedIcon}
              color={props.carrier.isFavoritedByCurrentUser ? "yellow" : "grey"}
              onClick={props.handleCarrierStar}
            />
          )}
        </div>
      </Table.Cell>

      <Table.Cell className={cx(styles.carrierNameCell, styles.truncate)}>
        <div
          className={styles.carrierName}
          title={props.cellValues.carrierName}
        >
          {props.cellValues.carrierName}
        </div>
        <div
          title={props.cellValues.carrierOwner}
          className={cx(
            styles.carrierOwner,
            props.isOwnedByCurrentUser ? styles.isCurrentUser : null
          )}
        >
          Owner: {props.cellValues.carrierOwner}
        </div>
        <div>MC#: {props.cellValues.mcNumber}</div>
        <div>DOT#: {props.cellValues.dotNumber}</div>
      </Table.Cell>

      <Table.Cell className={styles.truncate}>
        <div>
          <Icon name="phone" color="grey" size="small" />{" "}
          {props.cellValues.phone}
        </div>
        <div title={props.cellValues.email}>
          <Icon name="mail" color="grey" size="small" />{" "}
          {props.cellValues.email}
        </div>
        <div>
          <Icon name="globe" color="grey" size="small" />{" "}
          {props.cellValues.address}
        </div>
      </Table.Cell>

      <Table.Cell className={styles.truncate}>
        <div>{props.cellValues.lastActivityTime}</div>
        <div title={props.cellValues.lastActivitySummary}>
          {props.cellValues.lastActivitySummary}
        </div>
        <div title={props.cellValues.lastActivityNote}>
          {props.cellValues.lastActivityNote}
        </div>
      </Table.Cell>
    </>
  );
};

export default CarrierListRowCellsSmall;
