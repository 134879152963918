import { Link } from "react-router-dom";
import { Icon, Popup, Table } from "semantic-ui-react";

import { TURVO_SHIPMENT_URL } from "shared/constants";
import { replacePathParamInUrl } from "shared/utils/utility";
import { formatAddressForCarrierRow } from "components/CarrierList/List/utils/carrierListUtilities";
import { CarrierCapacityShipmentMatchDto } from "models/dto/CarrierCapacityShipmentMatch";

import styles from "./CarrierCapacityShipmentMatchTable.module.scss";
import { CarrierCapacityInfoDto } from "models/dto/CarrierCapacityInfoDto";

type CarrierCapacityShipmentMatchTableRowProps = {
  shipmentMatch: CarrierCapacityShipmentMatchDto;
  capacity: CarrierCapacityInfoDto<"Capacity">;
};

export const formatDateForMatchRow = ({
  dateString,
  timeZone,
  includeTime = true,
  timeZoneName,
}: {
  dateString: string;
  timeZone: string;
  includeTime?: boolean;
  timeZoneName?: "short";
}): string => {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  return new Intl.DateTimeFormat(window.navigator.language, {
    timeZone,
    timeZoneName,
    weekday: "short",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    ...(includeTime && {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    }),
  }).format(date);
};

const formatRouteData = (shipmentMatch: CarrierCapacityShipmentMatchDto) => {
  const firstRoute = shipmentMatch.route[0];
  const lastRoute = shipmentMatch.route[shipmentMatch.route.length - 1];

  const firstAddress = formatAddressForCarrierRow(
    firstRoute.city,
    firstRoute.state
  );
  const lastAddress = formatAddressForCarrierRow(
    lastRoute.city,
    lastRoute.state
  );

  const firstAppointmentDate = formatDateForMatchRow({
    dateString: firstRoute.appointmentStartDate,
    timeZone: firstRoute.timeZone,
    timeZoneName: "short",
  });

  const firstAppointmentDateRange = firstRoute.appointmentEndDate
    ? `${formatDateForMatchRow({
        dateString: firstRoute.appointmentStartDate,
        timeZone: firstRoute.timeZone,
      })} - ${formatDateForMatchRow({
        dateString: firstRoute.appointmentEndDate,
        timeZone: firstRoute.timeZone,
        timeZoneName: "short",
      })}`
    : firstAppointmentDate;

  const lastAppointmentDate = formatDateForMatchRow({
    dateString: lastRoute.appointmentStartDate,
    timeZone: lastRoute.timeZone,
    timeZoneName: "short",
  });

  const lastAppointmentDateRange = lastRoute.appointmentEndDate
    ? `${formatDateForMatchRow({
        dateString: lastRoute.appointmentStartDate,
        timeZone: lastRoute.timeZone,
      })} - ${formatDateForMatchRow({
        dateString: lastRoute.appointmentEndDate,
        timeZone: lastRoute.timeZone,
        timeZoneName: "short",
      })}`
    : lastAppointmentDate;

  return {
    firstAddress,
    lastAddress,
    firstAppointmentDate,
    firstAppointmentDateRange,
    lastAppointmentDate,
    lastAppointmentDateRange,
  };
};

const CarrierCapacityShipmentMatchTableRow = (
  props: CarrierCapacityShipmentMatchTableRowProps
) => {
  const {
    firstAddress,
    lastAddress,
    firstAppointmentDate,
    firstAppointmentDateRange,
    lastAppointmentDate,
    lastAppointmentDateRange,
  } = formatRouteData(props.shipmentMatch);

  return (
    <Table.Row key={props.shipmentMatch.tmsShipmentId}>
      <Table.Cell>
        <Link
          target="_blank"
          to={replacePathParamInUrl(
            TURVO_SHIPMENT_URL,
            props.shipmentMatch.tmsShipmentId.toString()
          )}
        >
          {props.shipmentMatch.tmsShipmentCustomId}
        </Link>
      </Table.Cell>
      <Table.Cell>{props.shipmentMatch.status}</Table.Cell>
      <Table.Cell>{props.shipmentMatch.equipmentType}</Table.Cell>
      <Table.Cell>
        <>
          <div className={styles.truncate} title={firstAddress}>
            ↓ {firstAddress}
          </div>
          <div className={styles.truncate} title={lastAddress}>
            ↓ {lastAddress}
          </div>
          <div>
            <strong>{`${props.shipmentMatch.routeDistance} ${props.shipmentMatch.routeDistanceUnit ? props.shipmentMatch.routeDistanceUnit : "mi"}`}</strong>
            {props.shipmentMatch.route.length > 2 ? (
              <Popup
                offset={[-10, 0]}
                trigger={<Icon name="exclamation circle" color="orange" />}
                content="Shipment has multiple stops"
              />
            ) : null}
          </div>
        </>
      </Table.Cell>
      <Table.Cell verticalAlign="top">
        <div title={firstAppointmentDateRange}>{firstAppointmentDate}</div>
        <div title={lastAppointmentDateRange}>{lastAppointmentDate}</div>
      </Table.Cell>
      <Table.Cell>
        {props.capacity.rate
          ? new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(props.capacity.rate)
          : "N/A"}
      </Table.Cell>
      <Table.Cell>
        {props.shipmentMatch.maxPay
          ? new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(props.shipmentMatch.maxPay)
          : "N/A"}
      </Table.Cell>
      <Table.Cell>{props.shipmentMatch.customerNames.join(", ")}</Table.Cell>
      <Table.Cell>{props.shipmentMatch.items.length}</Table.Cell>
      <Table.Cell className={styles.matchItems}>
        <Popup
          offset={[-10, 0]}
          wide="very"
          trigger={
            <div className={styles.truncate}>
              {props.shipmentMatch.items.map((item) => item.name).join(", ")}
            </div>
          }
        >
          <Popup.Header>Item Detail</Popup.Header>
          <Popup.Content>
            {props.shipmentMatch.items.map((item, index) => (
              <div key={`${index}-${item.name}`}>
                {item.vin ? `${item.name} - ${item.vin}` : item.name}
              </div>
            ))}
          </Popup.Content>
        </Popup>
      </Table.Cell>
    </Table.Row>
  );
};

export default CarrierCapacityShipmentMatchTableRow;
