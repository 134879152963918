import { Image, Label, SemanticCOLORS } from "semantic-ui-react";
import styles from "../CarrierList.module.scss";

const STATUS_MAP: Record<number, { color: SemanticCOLORS; text: string }> = {
  36: { color: "green", text: "Created" },
  37: { color: "blue", text: "Active" },
};

const getStatus = (status: number | null) =>
  STATUS_MAP[status ?? -1] ?? { color: "grey", text: "Inactive" };

type CarrierLabelBadgeProps = {
  turvoId: string;
  statusCodeId: number | null;
};

const CarrierLabelBadge = (props: CarrierLabelBadgeProps) => {
  const { color, text } = getStatus(props.statusCodeId);

  return (
    <Label ribbon title={text} color={color}>
      {props.turvoId && (
        <Image
          src="/assets/images/turvo-logo-mark.svg"
          alt="turvo-logo"
          className={styles.turvoLogo}
        />
      )}
      <div>{text}</div>
    </Label>
  );
};

export default CarrierLabelBadge;
