import { Image } from "semantic-ui-react";
import cx from "classnames";
import styles from "./CarrierTriangleBadge.module.scss";

type CarrierTriangleBadgeProps = {
  turvoId: string;
  statusCodeId: number | null;
};

const STATUS_MAP: Record<number, { className: string; text: string }> = {
  36: { className: styles.created, text: "Created" },
  37: { className: styles.active, text: "Active" },
};

const getStatus = (statusCodeId: number | null) =>
  STATUS_MAP[statusCodeId ?? -1] ?? {
    className: styles.inActive,
    text: "Inactive",
  };

const CarrierTriangleBadge = (props: CarrierTriangleBadgeProps) => {
  const { className, text } = getStatus(props.statusCodeId);
  const srcUrl = "/assets/images/turvo-logo-mark.svg";

  return (
    <div className={styles.mainContainer}>
      {props.turvoId && (
        <Image src={srcUrl} className={styles.topLogoImage} alt="turvo-logo" />
      )}
      <div className={cx(styles.topTriangle, className)}>
        <div className={styles.hoverTitleContainer}>{text}</div>
      </div>
    </div>
  );
};

export default CarrierTriangleBadge;
