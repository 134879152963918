import { useCallback, useEffect, useState } from "react";

import NavLink from "./NavbarItems/NavLink";
import NavLinkPopUp from "./NavbarItems/NavLinkPopUp";

import { Paths, Urls } from "../models";
import { fetchPaths } from "api/navigationApi";
import { useLocation } from "react-router-dom";

import styles from "./AdditionalComponents.module.scss";

const NavHeaderNavigation = () => {
  const [activeItem, setActiveItem] = useState<string>("/dashboard");

  const [paths, setPaths] = useState<Paths[]>([]);
  const [urls, setUrls] = useState<Urls>({
    defaultPath: "",
    homePath: "",
  });

  const fetchPathsAPI = useCallback(async () => {
    const response = await fetchPaths();
    const { data } = response ?? null;
    if (data) {
      setPaths(data.sections);
      setUrls({
        defaultPath: data.defaultUrl,
        homePath: data.homeUrl,
      });
    }
  }, []);

  useEffect(() => {
    fetchPathsAPI().catch((error) => console.error(error));
  }, [fetchPathsAPI]);

  useEffect(() => {
    if (activeItem === "/") {
      window.location.href = urls.defaultPath;
    }
  }, [activeItem, urls]);

  const location = useLocation();

  useEffect(() => {
    const pathname = location.pathname;

    if (pathname.startsWith("/carriers")) {
      setActiveItem("/carriers");
    } else if (pathname.startsWith("/customers")) {
      setActiveItem("/customers");
    } else if (pathname.startsWith("/locations")) {
      setActiveItem("/locations");
    } else if (pathname.startsWith("/users")) {
      setActiveItem("/users");
    } else if (pathname.startsWith("/map")) {
      setActiveItem("/map");
    } else {
      setActiveItem("/dashboard");
    }
  }, [location.pathname]);

  const getNavigationSubItems = (
    options: {
      title: string;
      link: string;
    }[]
  ) => {
    if (!options) return [];

    return options.map(({ title, link }) => ({ title, url: link }));
  };

  return (
    <ul className={styles.navigationList}>
      {paths.map((path) =>
        path.options.length === 0 ? (
          <NavLink
            key={`nav-link_${path.title}`}
            title={path.title}
            url={path.link}
            activePath={activeItem}
            setActivePath={setActiveItem}
          />
        ) : (
          <NavLinkPopUp
            key={`nav-link-popup_${path.title}`}
            title={path.title}
            url={path.link}
            items={getNavigationSubItems(path.options)}
            activePath={activeItem}
            setActivePath={setActiveItem}
          />
        )
      )}
    </ul>
  );
};

export default NavHeaderNavigation;
