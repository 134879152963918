import { CarriersListResponse } from "models/dto/CarriersListResponse";
import { CarrierDto } from "models/dto/CarrierDto";
import MarketplaceIcon from "./MarketplaceIcon";

const availableOrderedAttributes = ["active", "registered", "invited"];

export const validateAttributeValue = (
  value: boolean | string | number | null
): boolean => {
  if (typeof value === "string") {
    switch (value) {
      case "False":
      case "false":
        return false;
      default:
        return true;
    }
  } else {
    return Boolean(value);
  }
};

export const getMarketPlaceState = (
  profile: CarrierDto | CarriersListResponse
): string | null => {
  for (const attributeName of availableOrderedAttributes) {
    const attributes = profile.attributes ?? [];

    if (
      attributes.some(
        (attribute) =>
          attribute.attributeName.toLocaleLowerCase() === attributeName &&
          validateAttributeValue(attribute.value)
      )
    ) {
      return attributeName;
    }
  }

  return null;
};

const MarketplaceCarrierIcon = (props: {
  profile: CarrierDto | CarriersListResponse;
}) => {
  return <MarketplaceIcon state={getMarketPlaceState(props.profile)} />;
};

export default MarketplaceCarrierIcon;
