import { CountryDto } from "models/dto/CountryDto";
import { CountryStateDto } from "models/dto/CountryStateDto";
import { TurvoLookupDto } from "models/dto/TurvoLookupDto";

export const mapApiOptions = (options: TurvoLookupDto[] | null) => {
  if (!options) return [];

  return options.map((item) => ({
    key: item.id,
    text: item.value,
    value: +item.id,
  }));
};

export const mapApiStatesToOptions = (apiStates: string[] | null) => {
  if (!apiStates) return [];

  return apiStates.map((item) => ({
    key: item,
    text: item,
    value: item,
  }));
};

export const mapApiCountryOptions = (options: CountryDto[] | null) => {
  if (!options) return [];

  return options.map((item) => ({
    key: item.id,
    text: item.name,
    value: item.iso3,
  }));
};

export const mapApiStateOptions = (options: CountryStateDto[] | null) => {
  if (!options) return [];

  return options.map((item) => ({
    key: item.id,
    text: item.stateCode,
    value: item.stateCode,
  }));
};
