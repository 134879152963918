import { CarrierCapacityInfoDto } from "models/dto/CarrierCapacityInfoDto";
import { Icon, Popup, Table } from "semantic-ui-react";

import { shipmentMatchColSpan } from "./CarrierCapacityShipmentMatchTable";
import { formatDateForMatchRow } from "./CarrierCapacityShipmentMatchTableRow";
import { Option } from "models/Option";

import styles from "./CarrierCapacityShipmentMatchTable.module.scss";

type CarrierCapacityShipmentMatchCapacityTableRowProps = {
  capacity: CarrierCapacityInfoDto<"Capacity">;
  equipmentOptions: Option<number>[];
  message?: string;
};

const CarrierCapacityShipmentMatchCapacityTableRow = (
  props: CarrierCapacityShipmentMatchCapacityTableRowProps
) => {
  const equipmentName =
    props.equipmentOptions.find(
      (option) => option.value === props.capacity.equipmentModeCategoryId
    )?.text ?? "Unknown Equipment";
  const formattedCapacityRoute = `${props.capacity.origin ? props.capacity.origin : "Any Origin"} → ${props.capacity.destination ? props.capacity.destination : "Any Destination"}`;

  return (
    <Table.Row>
      <Table.Cell
        colSpan={shipmentMatchColSpan}
        className={styles.capacityCell}
      >
        <Popup
          offset={[-10, 0]}
          wide="very"
          trigger={
            <Icon circular inverted name="info" size="small" color="blue" />
          }
        >
          <Popup.Header>Capacity Information</Popup.Header>
          <Popup.Content>
            <div>
              {props.capacity.availabilityDate
                ? formatDateForMatchRow({
                    dateString: props.capacity.availabilityDate,
                    timeZone: "UTC",
                    includeTime: false,
                  })
                : "N/A"}
            </div>
            <div>{equipmentName}</div>
            <div>{formattedCapacityRoute}</div>
            {props.capacity.notes ? <div>{props.capacity.notes}</div> : null}
          </Popup.Content>
        </Popup>
        {formattedCapacityRoute}
        {props.message ? ` - ${props.message}` : null}
      </Table.Cell>
    </Table.Row>
  );
};

export default CarrierCapacityShipmentMatchCapacityTableRow;
